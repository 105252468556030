import React from 'react';
import { Box, TextField, Checkbox, MenuItem, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { adminLevel, errorMessages, labels, titles } from './constants';
import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Loader from '../../components/Loader/loader';
import SchoolAdminIcon from '../../components/Icons/SchoolAdminIcon';
import useAdminForm from './admin-form-hook';
import { NON_SPACE_REGEX } from '../../variables/constant';
import {
  formStyles,
  StyledDistrictFilter,
  StyledSchoolFilter,
  StyledStatesFilter,
  StyledTableButton,
} from './admin-tables-styled';
import { StyledSaveButton } from '../Districts/CreateDistrict/create-district.styled';
import { StyledFlexBox } from '../../components/Common/Common.styled';
import { palette } from '../../theme/palette';
import { useHistory } from 'react-router-dom';
import { adminsRoute, generateCreateOrEditRoute } from '../../variables/staticRoutes';

const AdminForm = () => {
  const {
    loading,
    errors,
    handleSubmit,
    control,
    register,
    createAdmin,
    checked,
    handleChange,
    level,
    handleLevelChange,
    adminID,
    onStateChange,
    onDistrictChange,
    onSchoolChange,
    selectedStateId,
    selectedDistrictId,
  } = useAdminForm();
  const breadcrumb = [generateCreateOrEditRoute('Admin', `admin/${level}`, adminID)];
  if (!adminID) {
    breadcrumb.unshift(adminsRoute(level));
  }

  const formTitle = adminID ? 'Update an admin' : 'Add a new admin';
  const history = useHistory();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
      >
        <Box>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems='baseline'
          >
            <SchoolAdminIcon
              pathfill={palette.customBlue.primaryBlue}
              sx={{
                height: '30px',
                width: '30px',
                mr: '16px',
              }}
            />
            <Typography variant="pageTitle" sx={{ textTransform: 'none', mb: 1 }}>
              {formTitle}
            </Typography>
          </Box>
        </Box>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createAdmin)}
          noValidate
        >
          <Box mt={2}>
            <Typography variant='sFormTitle' component='p'>Admin Level</Typography>
            <Box
              display="flex"
              flexDirection="row"
              flexGrow={1}
            >
              <StyledTableButton
                sx={{ textTransform: 'none' }}
                onClick={() => handleLevelChange(adminLevel.DISTRICT)}
                disabled={Boolean(adminID) && level !== adminLevel.DISTRICT}
                variant="contained"
                color={level === adminLevel.DISTRICT ? 'primary' : 'inherit'}
              >
                District-level Admin
              </StyledTableButton>
              <StyledTableButton
                sx={{ textTransform: 'none' }}
                onClick={() => handleLevelChange(adminLevel.SCHOOL)}
                disabled={Boolean(adminID) && level !== adminLevel.SCHOOL}
                variant="contained"
                color={level === adminLevel.SCHOOL ? 'primary' : 'inherit'}
              >
                School-level Admin
              </StyledTableButton>
            </Box>
            <Controller
              name="state_id"
              render={({ onChange, value }) => (
                <StyledStatesFilter
                  value={value}
                  onChange={(state) => {
                    onChange(state);
                    onStateChange();
                  }}
                  placeholder="Select a state"
                  idSelectOnly
                  disabled={Boolean(adminID)}
                />
              )}
              rules={{ required: errorMessages.stateHelperText }}
              control={control}
              defaultValue={null}
            />
            <Typography variant='sFormHelper' component='p'>
              {errors.state_id && errors.state_id.message}
            </Typography>
            <Controller
              name="district_id"
              render={({ onChange, value }) => (
                <StyledDistrictFilter
                  value={value}
                  onChange={(districtID, district) => {
                    onChange(districtID);
                    onDistrictChange(district?.state_id);
                  }}
                  placeholder="Select a district"
                  idSelectOnly
                  stateIds={selectedStateId as string}
                  disabled={Boolean(adminID)}
                />
              )}
              rules={{ required: errorMessages.districtHelperText }}
              control={control}
              defaultValue={null}
            />
            <Typography variant='sFormHelper' component='p'>
              {errors.district_id && errors.district_id.message}
            </Typography>
            {
              level === adminLevel.SCHOOL && (
                <>
                  <Controller
                    name="school_id"
                    render={({ onChange, value }) => (
                      <StyledSchoolFilter
                        value={value}
                        onChange={(schoolID, school) => {
                          onChange(schoolID);
                          onSchoolChange(school);
                        }}
                        placeholder="Select a school"
                        idSelectOnly
                        stateId={selectedStateId as string}
                        districtId={selectedDistrictId as string}
                        disabled={Boolean(adminID)}
                      />
                    )}
                    rules={{ required: errorMessages.schoolHelperText }}
                    control={control}
                    defaultValue={null}
                  />
                  <Typography variant='sFormHelper' component='p'>
                    {errors.school_id && errors.school_id.message}
                  </Typography>
                </>
              )
            }
            <Box
              display="flex"
              flexDirection="row"
              flexGrow={1}
            >
              <Box mr={3}>
                <Typography variant='sFormTitle' component='p'>Title</Typography>
                <Controller
                  name="title"
                  render={({ onChange, value }) => (
                    <TextField
                      id="title"
                      select
                      aria-describedby="title"
                      name="title"
                      sx={{ ...formStyles.title, ...formStyles.titleInput, width: '120px' }}
                      error={!!errors.title}
                      helperText={errors.title && errorMessages.titleHelperText}
                      value={value || titles?.[0]?.value}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                      defaultValue={titles?.[0]?.value}
                    >
                      {titles.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{ required: errorMessages.titleHelperText }}
                  control={control}
                  defaultValue={null}
                />
              </Box>
              <Box mr={3}>
                <Typography variant='sFormTitle' component='p'> First Name </Typography>
                <TextField
                  id="first-name"
                  aria-describedby="first-name"
                  name="first_name"
                  sx={{ ...formStyles.titleInput, width: '200px' }}
                  error={!!errors.first_name}
                  helperText={errors.first_name && errorMessages.firstNameHelperText}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
              <Box mr={3}>
                <Typography variant='sFormTitle' component='p'> Last Name </Typography>
                <TextField
                  id="last-name"
                  aria-describedby="last-name"
                  name="last_name"
                  sx={{ ...formStyles.titleInput, width: '200px' }}
                  error={!!errors.last_name}
                  helperText={errors.last_name && errorMessages.lastNameHelperText}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
            </Box>
            <StyledFlexBox sx={{ mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Admin ID</Typography>
              <TextField
                id="admin-id"
                aria-describedby="admin-id"
                name="admin_id"
                sx={{ ...formStyles.textFieldAdmin }}
                error={!!errors.admin_id}
                helperText={errors.admin_id && errorMessages.adminIDHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                  pattern: NON_SPACE_REGEX,
                })}
              />
            </StyledFlexBox>
            <StyledFlexBox sx={{ mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Job Title</Typography>
              <TextField
                id="job-title"
                aria-describedby="job-title"
                name="job_title"
                sx={{ ...formStyles.textFieldAdmin }}
                error={!!errors.job_title}
                helperText={errors.job_title && errorMessages.jobTitleHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
              />
            </StyledFlexBox>
            <StyledFlexBox sx={{ mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Email</Typography>
              <TextField
                id="email"
                aria-describedby="email"
                name="email"
                sx={{ ...formStyles.textFieldAdmin, ...formStyles.lowerCaseTextFieldAdmin }}
                error={!!errors.email}
                helperText={errors.email && errorMessages.emailHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
              />
            </StyledFlexBox>
            <StyledFlexBox sx={{ mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Phone (Optional)</Typography>
              <TextField
                id="phone-number"
                aria-describedby="phone-number"
                name="phone_number"
                sx={{ ...formStyles.textFieldAdmin }}
                inputRef={register({
                  required: false,
                })}
              />
            </StyledFlexBox>
            {
              !adminID && (
                <StyledFlexBox sx={{ mt: 0 }}>
                  <Typography variant='sFormTitle' component='p'>Password</Typography>
                  <TextField
                    id="password"
                    aria-describedby="password"
                    name="password"
                    sx={{ ...formStyles.textFieldAdmin }}
                    error={!!errors.password}
                    helperText={errors.password && errorMessages.passwordHelperText}
                    inputRef={register({
                      required: true,
                      validate: (value) => !!value.trim(),
                    })}
                  />
                </StyledFlexBox>
              )
            }
          </Box>
          {
            !adminID && (
              <Box display="flex" justifyContent="flex-start" sx={{ mt: 4, ml: -1.5 }}>
                <Checkbox
                  id="reopen-create-class-form"
                  aria-describedby="reopen-create-class-form"
                  name="reopen_create_checkbox"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  inputRef={register({
                    required: false,
                  })}
                />
                <Typography variant='sLabel' sx={{ pt: 1 }}>{labels.checkBoxLabel}</Typography>
              </Box>
            )
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, my: 2 }}>

            <StyledSaveButton
              variant="outlined"
              onClick={() => { history.push(adminID ? `/admins/${level}/${adminID}` : `/admins/${level}`); }}
            >
              Cancel
            </StyledSaveButton>
            <StyledSaveButton
              variant="contained"
              color="primary"
              type="submit"
            >
              {adminID ? 'Update' : 'Add Admin'}
            </StyledSaveButton>
          </Box>
        </form>
      </Box>
      <Loader open={loading} />
    </>
  );
};

export default React.memo(AdminForm);
