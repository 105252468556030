import { useEffect, useState } from 'react';

import {
  OrderBy,
  AdminStudentsListSortType,
  ManageStudentsListStudentDetailsFragmentFragment,
  useManageStudentsListQuery,
} from '../../../../../generated/graphql';

import useCreateClass from '../../create-class-hook';
// import { ROWS_PER_PAGE } from '../../../../../variables/constant';
// import usePagination from '../../../../../utils/usePagination';

const useManageStudents = () => {

  const {
    isTeacher, isSchoolAdmin, isDistrictAdmin,
    createClassInput,
    updateClassStudents,
    classDataFetched,
    setTotalTeacherStudentsCount,
    totalTeacherStudentsLoaded, setTotalTeacherStudentsLoaded,
  } = useCreateClass();

  const [availableStudents, setAvailableStudents] = useState<string[]>([]);
  const [selectedAvailableStudents, setSelectedAvailableStudents] = useState<string[]>([]);

  const [allocatedStudents, setAllocatedStudents] = useState<string[]>(createClassInput?.student_ids_to_add! as string[]);
  const [selectedAllocatedStudents, setSelectedAllocatedStudents] = useState<string[]>([]);


  const handleAddStudents = (addStudentIds: string[]) => {
    setAllocatedStudents?.((prevArray: any) => [...prevArray, ...addStudentIds]);
    setSelectedAvailableStudents([]);
    setSelectedAllocatedStudents([]);
  };

  const [removeStudentsWarning, setRemoveStudentsWarning] = useState<boolean>(false);
  const handleRemoveStudentsWithWarning = (removeStudentIds: string[]) => {
    const removingExistingStudents = removeStudentIds.filter(studentId => classDataFetched?.student_ids?.includes(studentId));
    if (removingExistingStudents.length <= 0) {
      setRemoveStudentsWarning(false);
      handleRemoveStudents(removeStudentIds);
    } else
      setRemoveStudentsWarning(true);
  };

  const handleRemoveStudents = (removeStudentIds: string[]) => {
    setRemoveStudentsWarning(false);
    setAllocatedStudents?.((prevArray: any) =>
      prevArray.filter((element: any) => !removeStudentIds.includes(element)),
    );
    setSelectedAvailableStudents([]);
    setSelectedAllocatedStudents([]);
  };

  const updateAvailableStudents = (studentsList: ManageStudentsListStudentDetailsFragmentFragment[], unavailableStudents: string[]) => {
    const studentsAvailable = studentsList?.filter((student) => !unavailableStudents.includes(student.id));
    setAvailableStudents(studentsAvailable?.map((student) => student.id) ?? []);
  };

  const [studentSearch, setStudentSearch] = useState('');

  const onStudentSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event!.target.value === '') {
      setStudentSearch?.('');
    } else {
      if (event!.target.value.length > 2) {
        setStudentSearch?.(event!.target.value);
      }
    }
  };

  const [sort, setSort] = useState(AdminStudentsListSortType.Name);
  const [order, setOrder] = useState(OrderBy.Asc);
  // const studentListPagination = usePagination();
  // refetch: studentListDataRefetch
  const { data: studentListData, loading: studentListDataLoading } = useManageStudentsListQuery({
    fetchPolicy: 'network-only',
    skip: !isDistrictAdmin && !isSchoolAdmin && !isTeacher,
    variables: {
      // ...((isDistrictAdmin || isSchoolAdmin) && {
      //   limit: 4 * 5 * ROWS_PER_PAGE,
      //   page: studentListPagination.page,
      // }),
      sort,
      orderBy: order as OrderBy,
      schoolId: createClassInput?.school_id,
      ...(studentSearch?.length ? { search: studentSearch } : {}),
      // ...(section ? { sectionID: section } : {}),
    },
  });

  // list of students [available+allocated]
  const studentsList = studentListData?.allStudentsList?.nodes ?? [];

  const onSortChange = (sortBy: AdminStudentsListSortType, orderBy: OrderBy) => {
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handleSort = (column: AdminStudentsListSortType) => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  useEffect(() => {
    if (!studentListDataLoading) {
      const studentsAvailable = studentsList?.filter((student) => !allocatedStudents.includes(student.id));
      setAvailableStudents(studentsAvailable?.map((student) => student.id) ?? []);
      if (studentSearch.length < 2) updateClassStudents?.(studentsList.filter(student => allocatedStudents.includes(student.id)));
      if (!totalTeacherStudentsLoaded && !!studentsList) {
        setTotalTeacherStudentsCount?.(studentsList.length);
        setTotalTeacherStudentsLoaded?.(true);
      }
    }
  }, [studentsList, studentListDataLoading]);

  useEffect(() => {
    updateAvailableStudents(studentsList, allocatedStudents);
    updateClassStudents?.(studentsList.filter(student => allocatedStudents.includes(student.id)));
  }, [allocatedStudents]);

  return {
    classTitle: createClassInput?.name,
    availableStudents, setAvailableStudents,
    selectedAvailableStudents, setSelectedAvailableStudents,
    allocatedStudents, setAllocatedStudents,
    selectedAllocatedStudents, setSelectedAllocatedStudents,
    handleAddStudents, handleRemoveStudents,
    loading: studentListDataLoading,
    isTableEmpty: studentListData ? !studentsList?.length : true,
    students: studentsList,
    studentSearch, setStudentSearch, onStudentSearch,
    sort, handleSort, order,
    removeStudentsWarning, setRemoveStudentsWarning, handleRemoveStudentsWithWarning,
  };
};

export default useManageStudents;
