import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import useClasses from './classes-hook';
import List from './List';
import StateFilter from '../../../components/StateFilter';
import DistrictFilter from '../../../components/DistrictFilter';
import SchoolFilter from '../../../components/SchoolFilter';
import TeacherFilter from '../../../components/TeacherFilter';
import { SectionCategory } from '../../../generated/graphql';
import useRole from '../../../hooks/useRole';
import { Link } from 'react-router-dom';
import RoutingPaths from '../../RoutingPath';

const Classes = () => {
  const {
    classes,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    selectedTeacher,
    onTeacherChange,
    selectedSchool,
    onSchoolChange,
    districtPermission,
    schoolPermission,
    rowsPerPage,
    setRowsPerPage,
  } = useClasses({ classTypes: [SectionCategory.Class, SectionCategory.CustomClass] });
  const { isSuperAdmin } = useRole();
  const filterColumnsCount = districtPermission ? 4 : (schoolPermission ? 2 : 1);
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="pageTitle">
            Classes
          </Typography>
        </Box>
        <Box>
          {isSuperAdmin && (
            <Button
              variant="outlined"
              color="primary"
              disableRipple={true}
              component={Link}
              to={RoutingPaths.AdminCreateClass}
            >
              Add Class
            </Button>
          )}
        </Box>
      </Box>
      <Grid container mt={2}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Box
            display="grid"
            gridTemplateColumns={`repeat(${filterColumnsCount}, 20%) 1fr 200px`}
            sx={{
              gridGap: 24,
            }}
          >
            {districtPermission && (
              <>
                <StateFilter
                  value={selectedState}
                  onChange={onStateChange}
                  idSelectOnly
                  placeholder="All states"
                />
                <DistrictFilter
                  value={selectedDistrict}
                  onChange={onDistrictChange}
                  stateIds={selectedState ?? undefined}
                  idSelectOnly
                  placeholder="All districts"
                />
              </>
            )}
            {schoolPermission
              && (
                <SchoolFilter
                  value={selectedSchool}
                  onChange={onSchoolChange}
                  stateId={selectedState ?? undefined}
                  districtId={selectedDistrict ?? undefined}
                  idSelectOnly
                  placeholder="All schools"
                />
              )}
            <TeacherFilter
              value={selectedTeacher}
              onChange={onTeacherChange}
              stateId={selectedState ?? undefined}
              districtId={selectedDistrict ?? undefined}
              schoolId={selectedSchool ?? undefined}
              idSelectOnly
              placeholder="All teachers"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} container direction="row" justifyContent="flex-end">
          {!isSuperAdmin && <Button
            variant="contained"
            color="primary"
            component={Link}
            to={RoutingPaths.CreateClass}
            sx={{ px: 4 }}
          >
            Add Class
          </Button>}
        </Grid>
      </Grid>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading}
          totalCount={totalCount}
          order={order}
          pagination={pagination}
          sort={sort}
          classes={classes}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default React.memo(Classes);
