import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { palette } from '../../theme/palette';
import { StyledTableCell, StyledTableSort, tableWithNoDataStyles } from '../Common/Common.styled';
import { StyledChipBox } from '../Chip/ChipsCount.styled';
import { StyledTableContainer } from '../../pages/Assignments/Assignments.styled';
import NoResults from '../NoResults';
import WavingLadyFrame from '../Icons/WavingLadyFrame';
import StudentRow from './StudentRow';

import { ManageStudentsListStudentDetailsFragmentFragment, OrderBy, AdminStudentsListSortType } from '../../generated/graphql';

import useManageStudentsTable from './manage-students-table-hook';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

interface Props {
  title: string;
  loading?: boolean
  students?: ManageStudentsListStudentDetailsFragmentFragment[];
  selectedStudents?: string[];
  setSelectedStudents?: Function;
  studentsToSkip?: string[];
  isTableEmpty?: boolean;
  sort?: string,
  order?: any;
  handleSort?: ((column: string) => void) | Function,
}

const manageClassStyles = {
  tableContainerRoot: {
    '&.MuiTableContainer-root': {
      borderColor: palette.customBlue.primaryBlue,
    },
  },
  tableContainer: {
    height: 'calc(100vh - 350px)',
  },
  tableTitle: {
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: palette.fontColors.fontBlack,
  },
  whiteCheckbox: {
    color: palette.customWhite.main,
  },
};

const ManageStudentsTable = ({
  title,
  loading, isTableEmpty, students,
  selectedStudents, setSelectedStudents,
  studentsToSkip,
  sort,
  order,
  handleSort,
}: Props) => {

  const [studentsToRender, setStudentsToRender] = useState<ManageStudentsListStudentDetailsFragmentFragment[]>();

  useEffect(() => {
    if (!loading) {
      setStudentsToRender(students?.filter(student => !studentsToSkip?.includes(student?.id)));
    }
  }, [loading, students, studentsToSkip]);

  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const { studentSelectToggle, isStudentSelected } = useManageStudentsTable({ selectedStudents, setSelectedStudents });

  return (
    <Box
      display="grid"
      width="100%"
      mt={3}
    >
      <Box
        display="flex"
        width="100%"
        mb={2}
        alignItems="center"
      >
        <Typography sx={{ ...manageClassStyles.tableTitle }} >
          {title}
        </Typography>
        <Box ml={2}>
          <StyledChipBox
            backgroundColor={palette.customBlue.chipDark}
            hascolor={palette.customWhite.main}
            label={(
              <NumberFormat
                displayType="text"
                value={loading ? '-' : studentsToRender?.length}
                thousandSeparator
              />
            )}
          />
        </Box>
      </Box>
      <Box>
        <StyledTableContainer
          sx={{ ...manageClassStyles.tableContainerRoot, ...manageClassStyles.tableContainer }}
        >
          <Table
            stickyHeader
            aria-label="students table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ textAlign: 'left', width: '65%', pl: 6 }}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={() => handleSort?.(AdminStudentsListSortType.Name)}
                    active={sort === AdminStudentsListSortType.Name}
                    IconComponent={ArrowDownIcon}
                  >
                    Name
                  </StyledTableSort>
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'left', width: '5%' }}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={() => handleSort?.(AdminStudentsListSortType.Grades)}
                    active={sort === AdminStudentsListSortType.Grades}
                    IconComponent={ArrowDownIcon}
                  >
                    Grade
                  </StyledTableSort>
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'left', width: '30%' }}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={() => handleSort?.(AdminStudentsListSortType.StudentId)}
                    active={sort === AdminStudentsListSortType.StudentId}
                    IconComponent={ArrowDownIcon}
                  >
                    Student ID
                  </StyledTableSort>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && studentsToRender?.map((student) => (
                <StudentRow
                  key={student?.id}
                  student={student}
                  onChange={studentSelectToggle}
                  isSelected={isStudentSelected}
                />
              ))}
              {isTableEmpty && !loading && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <NoResults
                      label="No Students"
                      description="There are no students in this section"
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </Box>
  );
};
export default ManageStudentsTable;
