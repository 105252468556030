import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, SxProps } from '@mui/material';
import ClassForm from './ClassForm';

import { CreateClassInput, CreateClassInit, CreateClassContext } from './create-class-hook';


const CreateClass = () => {

  const {
    routeData,
    loading, setLoading,
    mySchoolDataLoading, mySchoolData,
    myDistrictDataLoading, myDistrictData,
    teacherDetailsLoaded, setTeacherDetailsLoaded,
    teacherDetails, setTeacherDetails,
    teacherSchools, setTeacherSchools,
    createClassInput, setCreateClassInput,
    editClassDataLoaded, setEditClassDataLoaded,
    classDataFetched, setClassDataFetched,
    selectedSchool, setSelectedSchool,
    selectedPrimaryTeacher, setSelectedPrimaryTeacher,
    selectedSecondaryTeachers, setSelectedSecondaryTeachers,
    classStudents, setClassStudents,
    totalTeacherStudentsCount, setTotalTeacherStudentsCount,
    totalTeacherStudentsLoaded, setTotalTeacherStudentsLoaded,
  } = CreateClassInit();

  const createClassForm = useForm<CreateClassInput>({
    defaultValues: createClassInput,
    reValidateMode: 'onChange',
  });

  return (
    <>
      <Box sx={{ mt: -2 } as SxProps}>
        <CreateClassContext.Provider value={{
          routeData,
          loading, setLoading,
          mySchoolDataLoading, mySchoolData,
          myDistrictDataLoading, myDistrictData,
          teacherDetailsLoaded, setTeacherDetailsLoaded,
          teacherDetails, setTeacherDetails,
          teacherSchools, setTeacherSchools,
          createClassInput, setCreateClassInput,
          editClassDataLoaded, setEditClassDataLoaded,
          classDataFetched, setClassDataFetched,
          selectedSchool, setSelectedSchool,
          selectedPrimaryTeacher, setSelectedPrimaryTeacher,
          selectedSecondaryTeachers, setSelectedSecondaryTeachers,
          classStudents, setClassStudents,
          totalTeacherStudentsCount, setTotalTeacherStudentsCount,
          totalTeacherStudentsLoaded, setTotalTeacherStudentsLoaded,
        }}
        >
          <FormProvider {...createClassForm}>
            <Switch>
              <Route
                path={[
                  `${routeData?.rootPath}/details`,
                  `${routeData?.rootPath}/manage-students`,
                  `${routeData?.rootPath}/summary`,
                ]}
                component={ClassForm}
                exact
              />
              <Redirect
                from={`${routeData?.rootPath}`}
                to={`${routeData?.rootPath}/details`}
              />
            </Switch>
          </FormProvider>
        </CreateClassContext.Provider>
      </Box>
    </>
  );
};

export default CreateClass;
