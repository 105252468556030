import { useParams } from 'react-router-dom';
import { UserPermissions, useSectionDetailQuery } from '../../../generated/graphql';
import usePermission from '../../../hooks/usePermission';
import useRole from '../../../hooks/useRole';

interface RouteProp {
  id: string
}

const useClass = () => {
  const { id } = useParams<RouteProp>();
  const { data, loading } = useSectionDetailQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });
  const { hasPermission: editClassPermission } = usePermission(UserPermissions.EditClasses);
  const { isSuperAdmin, isSchoolAdmin } = useRole();
  return {
    loading,
    classDetail: data?.sectionDetails,
    editClassPermission,
    isSuperAdmin, isSchoolAdmin,
    classID: id,
  };
};

export default useClass;
