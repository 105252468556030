import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import RoutingPaths from '../../RoutingPath';
import useRedirect from '../../../hooks/useRedirect';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType, StepFormNavigation } from '../../../variables/types';
import { openSnackbar } from '../../../components/Notifier';
import { SchoolValue } from '../../../components/SchoolFilterTeacher';

import {
  ManageStudentsListStudentDetailsFragmentFragment, School, SectionDetails, Teacher, TeacherFilterFragmentFragment,
  useGetTeacherByUserQuery, useMyDistrictQuery, useMySchoolQuery, useSectionDetailQuery, useTeacherSchoolFilterQuery,
  useUpsertClassMutation, useRemoveInProgressSubmissionsOfSectionMutation,
  DistrictData,
} from '../../../generated/graphql';
import { useAuthProvider } from '../../../core/authContext';
import useRole from '../../../hooks/useRole';
import useLocalStorageState from '../../../hooks/useLocalStorageState';

export interface StepProp {
  title: string,
}

export interface RouteParamProp {
  id?: string,
  step?: string,
}

export interface GetRouteDataProps extends RouteParamProp {
  pathname?: string,
}

export interface RouteDataProp {
  id?: string,
  step?: string,
  action?: string,
  rootPath?: string,
  currentStep?: number,
}

export const GetRouteData = ({ id, step, pathname }: GetRouteDataProps): RouteDataProp => {
  const action: string = pathname?.includes('/edit') ? 'edit' : 'create';
  const rootPath: string = `${RoutingPaths.Classes}/${!id ? '' : (id + '/')}${action}`;
  var currentStep: number = 1;
  if (step === 'manage-students') currentStep = 2;
  else if (step === 'summary') currentStep = 3;
  else currentStep = 1;
  return {
    id,
    step,
    action,
    rootPath,
    currentStep,
  };
};

export type CreateClassInput = {
  id?: string,
  state_id: string,
  district_id: string,
  school_id: string,
  teacher_id: string,
  secondary_teacher_ids: (string | undefined)[] | any,
  name: string,
  section_number: string,
  period: number,
  grades?: string[],
  student_ids_to_add?: string[],
  student_ids_to_remove?: string[],
};

export const CreateClassInputInitial = {
  id: '',
  state_id: '',
  district_id: '',
  school_id: '',
  teacher_id: '',
  secondary_teacher_ids: [],
  name: '',
  section_number: '',
  period: 0,
  grades: [],
  student_ids_to_add: [],
  student_ids_to_remove: [],
};

export interface CreateClassContextValues {
  loading?: boolean,
  setLoading?: Function,
  routeData?: RouteDataProp,
  // Admins Details
  mySchoolDataLoading?: boolean,
  mySchoolData?: any | School,
  myDistrictDataLoading?: boolean,
  myDistrictData?: any | DistrictData,
  // Teacher Details Tracker
  teacherDetailsLoaded?: boolean,
  setTeacherDetailsLoaded?: Function,
  teacherDetails?: Teacher,
  setTeacherDetails?: Function,
  teacherSchools?: School[],
  setTeacherSchools?: Function,
  // Create Class Data trackers
  createClassInput?: CreateClassInput,
  setCreateClassInput?: Function,
  // Edit Class Trackers
  editClassDataLoaded?: boolean,
  setEditClassDataLoaded?: Function,
  classDataFetched?: SectionDetails,
  setClassDataFetched?: Function,
  // Class Form Filter trackers
  selectedSchool?: SchoolValue,
  setSelectedSchool?: Function,
  selectedPrimaryTeacher?: TeacherFilterFragmentFragment,
  setSelectedPrimaryTeacher?: Function,
  selectedSecondaryTeachers?: TeacherFilterFragmentFragment[],
  setSelectedSecondaryTeachers?: Function,
  classStudents?: ManageStudentsListStudentDetailsFragmentFragment[],
  setClassStudents?: Function,
  totalTeacherStudentsCount?: number,
  setTotalTeacherStudentsCount?: Function,
  totalTeacherStudentsLoaded?: boolean,
  setTotalTeacherStudentsLoaded?: Function,
}

export const CreateClassContext = createContext<CreateClassContextValues>({
  createClassInput: CreateClassInputInitial,
  setCreateClassInput: () => { },
  selectedSchool: undefined,
  setSelectedSchool: () => { },
  selectedPrimaryTeacher: undefined,
  setSelectedPrimaryTeacher: () => { },
  selectedSecondaryTeachers: [],
  setSelectedSecondaryTeachers: () => { },
  classStudents: [],
  setClassStudents: () => { },
});

export const CreateClassInit = () => {
  const { id, step } = useParams<RouteParamProp>();
  const location = useLocation();
  const routeData: RouteDataProp = GetRouteData({ id, step, pathname: location.pathname });

  const [loading, setLoading] = useState<boolean>(false);

  const [teacherDetailsLoaded, setTeacherDetailsLoaded] = useState<boolean>(false);
  const [teacherDetails, setTeacherDetails] = useState<any>();
  const [teacherSchools, setTeacherSchools] = useState<any>();

  const [createClassInput, setCreateClassInput] = useState(CreateClassInputInitial);
  const [editClassDataLoaded, setEditClassDataLoaded] = useState<boolean>(false);
  const [classDataFetched, setClassDataFetched] = useState<any>();
  const [totalTeacherStudentsCount, setTotalTeacherStudentsCount] = useState<number>(0);
  const [totalTeacherStudentsLoaded, setTotalTeacherStudentsLoaded] = useState<boolean>(false);

  const [selectedSchool, setSelectedSchool] = useState<SchoolValue>();
  const [selectedPrimaryTeacher, setSelectedPrimaryTeacher] = useState<TeacherFilterFragmentFragment>();
  const [selectedSecondaryTeachers, setSelectedSecondaryTeachers] = useState<TeacherFilterFragmentFragment[]>([]);
  const [classStudents, setClassStudents] = useState<ManageStudentsListStudentDetailsFragmentFragment[]>([]);

  const { isSchoolAdmin, isDistrictAdmin } = useRole();
  // fetch current user's district/school data
  const { data: myDistrictData, loading: myDistrictDataLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: !isDistrictAdmin,
  });
  const { data: mySchoolData, loading: mySchoolDataLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: !isSchoolAdmin,
  });

  return {
    routeData,
    loading, setLoading,
    mySchoolDataLoading, mySchoolData: mySchoolData?.mySchool!,
    myDistrictDataLoading, myDistrictData: myDistrictData?.myDistrict!,
    teacherDetailsLoaded, setTeacherDetailsLoaded,
    teacherDetails, setTeacherDetails,
    teacherSchools, setTeacherSchools,
    createClassInput, setCreateClassInput,
    editClassDataLoaded, setEditClassDataLoaded,
    classDataFetched, setClassDataFetched,
    selectedSchool, setSelectedSchool,
    selectedPrimaryTeacher, setSelectedPrimaryTeacher,
    selectedSecondaryTeachers, setSelectedSecondaryTeachers,
    classStudents, setClassStudents,
    totalTeacherStudentsCount, setTotalTeacherStudentsCount,
    totalTeacherStudentsLoaded, setTotalTeacherStudentsLoaded,
  };
};

export const useCreateClass = () => {
  const location = useLocation();
  const { navigateTo } = useRedirect();

  const { getUser } = useAuthProvider();
  const user = getUser();
  const { isTeacher, isSchoolAdmin, isDistrictAdmin } = useRole();

  const [blockNavigation, setBlockNavigation] = useState<boolean>(false);
  const [noStudentWarning, setNoStudentWarning] = useState<boolean>(false);
  const [upsertClass, { loading: upsertClassInProgress }] = useUpsertClassMutation();
  const [removeStudentsAssignments, { loading: removeStudentsAssignmentsInProgress }] = useRemoveInProgressSubmissionsOfSectionMutation();

  const {
    routeData,
    loading, setLoading,
    mySchoolDataLoading, mySchoolData,
    myDistrictDataLoading, myDistrictData,
    teacherDetailsLoaded, setTeacherDetailsLoaded,
    teacherDetails, setTeacherDetails,
    teacherSchools, setTeacherSchools,
    createClassInput, setCreateClassInput,
    editClassDataLoaded, setEditClassDataLoaded,
    classDataFetched, setClassDataFetched,
    selectedSchool, setSelectedSchool,
    selectedPrimaryTeacher, setSelectedPrimaryTeacher,
    selectedSecondaryTeachers, setSelectedSecondaryTeachers,
    classStudents, setClassStudents,
    totalTeacherStudentsCount, setTotalTeacherStudentsCount,
    totalTeacherStudentsLoaded, setTotalTeacherStudentsLoaded,
  } = useContext(CreateClassContext);

  const [addAnotherClass, setAddAnotherClass] = useState<boolean>(false);
  const [classNameState, setClassNameState] = useState(createClassInput?.name ?? '');

  const [classCreatedCount, setClassCreatedCount] = useLocalStorageState('class-created-count', 0);

  const {
    reset,
  } = useFormContext();

  const onSchoolChange = (schoolData: any) => {
    if (selectedSchool !== schoolData) { setSelectedSecondaryTeachers?.([]); }
    setSelectedSchool?.(schoolData);
  };

  const onPrimaryteacherChange = (teacher: any) => {
    setSelectedPrimaryTeacher?.(teacher);
  };

  const updateSecondaryTeachers = (teachers: any) => {
    setSelectedSecondaryTeachers?.(teachers);
  };

  const updateClassStudents = (allocatedStudents: ManageStudentsListStudentDetailsFragmentFragment[]) => {
    setClassStudents?.((prevArray: any) => {
      let newClassStudents: ManageStudentsListStudentDetailsFragmentFragment[] = [...prevArray];
      // To Allocate new Students.
      allocatedStudents.forEach((student: ManageStudentsListStudentDetailsFragmentFragment) => {
        if (!prevArray.includes(student)) {
          newClassStudents.push(student);
        }
      });
      // To deAllocate old students
      newClassStudents = newClassStudents.filter((student) => allocatedStudents.includes(student));
      return newClassStudents;
    });
  };

  const upsert = async (classPayload: CreateClassInput, addAnotherNewClass: boolean = false) => {
    try {
      if (classPayload.id && classPayload.student_ids_to_remove?.length){
        // eslint-disable-next-line no-unused-vars
        const response = await removeStudentsAssignments({
          variables: {
            sectionID: classPayload.id,
            studentIDs: classPayload.student_ids_to_remove,
          },
        });
        if (!response?.data?.removeInProgressSubmissionsOfSection) {
          openSnackbar({ message: 'Error in removing in progress PTs.' }, NotifierType.Success);
          return;
        }
      }
    } catch (err) {
      openSnackbar({ message: 'Error in removing students' }, NotifierType.Success);
      return;
    }
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await upsertClass({
        variables: {
          input: {
            id: classPayload.id ? classPayload.id : undefined,
            district_id: classPayload?.district_id,
            school_id: classPayload.school_id,
            teacher_id: classPayload.teacher_id,
            period: classPayload.period ? classPayload.period : undefined,
            name: classPayload.name?.trim(),
            secondary_teacher_ids: classPayload.secondary_teacher_ids,
            student_ids_to_add: classPayload.student_ids_to_add ?? [],
            student_ids_to_remove: classPayload.student_ids_to_remove ?? [],
          },
        },
      });
      const rootPath = routeData?.rootPath;
      if (addAnotherNewClass) {
        const resetClassPayload = {
          ...classPayload,
          id: '',
          period: 0,
          name: '',
          secondary_teacher_ids: [],
          student_ids_to_add: [],
          student_ids_to_remove: [],
        };
        reset(resetClassPayload, {
          isSubmitted: false,
        });
        setCreateClassInput?.(resetClassPayload);
        setSelectedSecondaryTeachers?.([]);
        setClassStudents?.([]);
        setAddAnotherClass(false);
        navigateTo(`${rootPath}/details`);
      } else {
        setClassCreatedCount(classCreatedCount + 1);
        navigateTo(RoutingPaths.TeacherClasses);
        backToDashboard();
      }
      openSnackbar({
        message: `${classPayload.name} Has Been ${routeData?.action === 'create' ? 'Created' : 'Updated'} Successfully`,
      }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCreateClassInput = (classData: Object) => {
    setCreateClassInput?.({
      ...createClassInput,
      ...classData,
    });
  };
  const rootPath = routeData?.rootPath;
  const backToDashboard = () => {
    if (isSchoolAdmin || isDistrictAdmin) {
      navigateTo(`${RoutingPaths.AdminClasses}`);
    } else {
      navigateTo(RoutingPaths.TeacherClasses);
    }
  };
  const handlePrev = () => {
    if (matchPath(location.pathname, `${rootPath}/manage-students`)) {
      navigateTo(`${rootPath}/details`);
    } else if (matchPath(location.pathname, `${rootPath}/summary`)) {
      navigateTo(`${rootPath}/manage-students`);
    }
  };

  const handleNextOnStep2 = () => {
    setNoStudentWarning(false);
    navigateTo(`${rootPath}/summary`);
  };

  const handleNext = () => {
    if (matchPath(location.pathname, `${rootPath}/details`)) {
      navigateTo(`${rootPath}/manage-students`);
    } else if (matchPath(location.pathname, `${rootPath}/manage-students`)) {
      if ((classStudents && classStudents?.length > 0) || totalTeacherStudentsCount === 0)
        navigateTo(`${rootPath}/summary`);
      else
        setNoStudentWarning(true);
    } else if (matchPath(location.pathname, `${rootPath}/summary`)) {
      if (createClassInput) {
        upsert?.({
          ...createClassInput,
          ...(selectedSchool && { district_id: selectedSchool?.district_id! }),
        }, addAnotherClass);
      }
    }
  };

  const [submitType, setSubmitType] = useState<StepFormNavigation>(StepFormNavigation.Next);

  const submitStep = (formData: Object) => {
    if (matchPath(location.pathname, `${rootPath}/manage-students`)) {
      let studentIdsToAdd = classStudents?.map((student) => student.id);
      let studentIdsToRemove: (string | null)[] = [];
      if (classDataFetched) {
        studentIdsToRemove = classDataFetched?.student_ids?.filter((studentId) => !studentIdsToAdd?.includes(studentId!)) ?? [];
      }
      updateCreateClassInput?.({
        student_ids_to_add: studentIdsToAdd,
        student_ids_to_remove: studentIdsToRemove,
        grades: [...new Set(classStudents?.map((student) => student.grade))],
      });
    } else if (matchPath(location.pathname, `${rootPath}/details`)) {
      if (selectedSchool !== createClassInput?.school_id) setTotalTeacherStudentsLoaded?.(false);
      updateCreateClassInput?.(formData);
    } else {
      updateCreateClassInput?.(formData);
    }
    if (submitType === StepFormNavigation.Back) {
      handlePrev();
    } else {
      handleNext();
    }
  };

  const { data: teacherSchoolsFetched, loading: teacherSchoolsLoading } = useTeacherSchoolFilterQuery({
    fetchPolicy: 'network-only',
    skip: !isTeacher,
  });

  useEffect(() => {
    if (!teacherSchoolsLoading && teacherSchoolsFetched) {
      const teacherSchoolDet = teacherSchoolsFetched?.teacherSchools;
      setTeacherSchools?.(teacherSchoolDet);
      if (!createClassInput?.school_id && teacherSchoolDet.length > 0) {
        setSelectedSchool?.(teacherSchoolDet[0]);
        if (teacherSchoolDet.length === 1) {
          updateCreateClassInput({
            school_id: teacherSchoolDet[0]?.id,
          });
        }
      }
    }
  }, [teacherSchoolsLoading]);

  const { data: teacherDetailsFetched, loading: teacherDetailsLoading } = useGetTeacherByUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      userID: user.id,
    },
    skip: teacherDetailsLoaded || !isTeacher || !user.id,
  });

  useEffect(() => {
    if (!teacherDetails && !teacherDetailsLoading && teacherDetailsFetched) {
      const teacherDet = teacherDetailsFetched?.teacherByUser;
      setTeacherDetails?.(teacherDet);
      setTeacherDetailsLoaded?.(true);
      if (!createClassInput?.teacher_id) {
        updateCreateClassInput({
          teacher_id: teacherDet.id,
        });
      }
    }
  }, [teacherDetailsLoading]);

  const { data: sectionDataLoaded, loading: sectionDataLoading } = useSectionDetailQuery({
    fetchPolicy: 'network-only',
    skip: editClassDataLoaded || !routeData?.id,
    variables: {
      id: routeData?.id!,
    },
  });

  const classDefaultValues = useMemo(() => {
    if (!sectionDataLoading) {
      return {
        id: sectionDataLoaded?.sectionDetails?.id,
        state_id: '',
        district_id: sectionDataLoaded?.sectionDetails?.district_id,
        school_id: sectionDataLoaded?.sectionDetails?.school_id,
        teacher_id: sectionDataLoaded?.sectionDetails?.teachers?.find(teacher => teacher?.primary_teacher === true)?.id,
        secondary_teacher_ids: sectionDataLoaded?.sectionDetails?.teachers?.filter(teacher => teacher?.primary_teacher !== true)?.map(teacher => teacher?.id),
        name: sectionDataLoaded?.sectionDetails?.name,
        section_number: sectionDataLoaded?.sectionDetails?.section_number,
        period: sectionDataLoaded?.sectionDetails?.period ?? 0,
        grades: [],
        student_ids_to_add: sectionDataLoaded?.sectionDetails?.student_ids,
        student_ids_to_remove: [],
        //
      };
    }
    return { ...createClassInput };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionDataLoaded, sectionDataLoading]);

  useEffect(() => {
    if (editClassDataLoaded !== undefined) {
      if (!sectionDataLoading && sectionDataLoaded) {
        if (!editClassDataLoaded) {
          updateCreateClassInput({
            ...classDefaultValues,
          });
          setEditClassDataLoaded?.(true);
        }
        setClassDataFetched?.(sectionDataLoaded?.sectionDetails);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classDefaultValues, sectionDataLoaded, sectionDataLoading]);

  useEffect(() => {
    reset(createClassInput);
  }, [createClassInput]);


  return {
    routeData,
    loading: loading || upsertClassInProgress || removeStudentsAssignmentsInProgress || sectionDataLoading
      || teacherDetailsLoading || teacherSchoolsLoading || myDistrictDataLoading || mySchoolDataLoading,
    setLoading,
    isTeacher,
    isSchoolAdmin, mySchoolData,
    isDistrictAdmin, myDistrictData,
    teacherDetails, teacherSchools,
    addAnotherClass, setAddAnotherClass,
    createClassInput, setCreateClassInput,
    classNameState, setClassNameState,
    editClassDataLoaded, setEditClassDataLoaded,
    classDataFetched, setClassDataFetched,
    selectedSchool, setSelectedSchool, onSchoolChange,
    selectedPrimaryTeacher, setSelectedPrimaryTeacher, onPrimaryteacherChange,
    selectedSecondaryTeachers, setSelectedSecondaryTeachers, updateSecondaryTeachers,
    classStudents, setClassStudents, updateClassStudents,
    setTotalTeacherStudentsCount,
    totalTeacherStudentsLoaded, setTotalTeacherStudentsLoaded,
    updateCreateClassInput,
    handlePrev,
    handleNext,
    submitType, setSubmitType,
    submitStep,
    backToDashboard,
    blockNavigation, setBlockNavigation,
    leavingCreateClass: location.pathname.includes(RoutingPaths.CreateClass),
    noStudentWarning, setNoStudentWarning,
    handleNextOnStep2,
  };

};

export default useCreateClass;
